.navbar {
    /*display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    grid-gap: 2em;
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px 5%;*/
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    column-gap: 50px;
    justify-content: space-between;
    align-items: center;
    padding: 20px 5%;
    background-color: var(--white);
    border-bottom: 1px solid var(--grey)
}

.navtitle {
    justify-self: right;
}

.navbar img {
    /*width: 285px;*/
    height: 43px;
}

.navbar h3 {
    color: var(--dark-blue);
    margin: 0;
    text-align: right;
}

/* .navbar .links {
    margin-left: auto;
} */

/* .navbar a {
    margin-left: 16px;
    text-decoration: none;
    padding: 6px;
}

.navbar a:hover {
    color: #f1356d;
} */

@media screen and (max-width: 991px) {}

@media screen and (max-width: 568px) {
    .navbar {
        flex-wrap: wrap;
        column-gap: 0;
    }

    .navbar h3 {
        text-align: left;
        margin: 20px 0;
        font-size: 20px;
        line-height: 28px;
    }
}

@media screen and (max-width: 425px) {

    .navbar img {
        width: 100%;
        height: auto;
    }

    .navbar h3 {
        margin: 8px 0 5px 0;
    }

}

@media screen and (max-width: 355px) {
   
}
