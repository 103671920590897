/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */

@import url("https://use.typekit.net/kst1orw.css");

/* base styles */
* {
  margin: 0;
  padding: 0;
  font-family: "proxima-nova", sans-serif;
  color: var(--dark-grey)
}

:root {
    /* colours */
    --darker-blue: #041832;
    --dark-blue: #002856;
    --blue: #005980;
    --lt-blue: #e7f2f6;
    --dark-grey: #333;
    --teal: #26cad3;
    --mid-grey: #d0d0d0;
    --inactive-grey: #a5a5a5;
    --grey: #e8e8e8;
    --lt-grey: #f7f8fa;
    --white: #ffffff;
}

hr {
  border: 1px solid var(--grey);
}

.btn-group{
    width:100%;
    box-sizing:border-box;
}