.resetWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.resetWrapper .resetIcon {
    width: 20px;
    height: auto;
}

.resetWrapper p {
    margin: 0 0 0 5px !important;
}

.resetWrapper:hover {
    opacity: 0.5;
}