h1 {
    font-weight: bold;
}

h2 {
    line-height: 1.4;
}

.container {
    height: calc(100vh - 322px);
    display: grid;
    place-items: center;
    padding: 5%;
}

.inner {
    text-align: center;
}

.goBack {
    margin-top: 16px;
    cursor: pointer;
    color: var(--GEH-Purple)
}