.filterContainer {
    /* margin: 20px 0; */
    padding: 0 0 10px 0;
}

.item {
    margin: 0 0 10px 0;
    border-bottom: 1px solid var(--grey);
}

.formBtnContainer {
    margin: 20px 25px 0 0;
}

/* Responsive styles */
@media screen and (max-width: 991px) {
    .filterContainer {
        margin-bottom: 0;
        padding: 0;
    }
}