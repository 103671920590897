.cookiesContainer {
    padding: 5%;
    display: flex;
    flex-direction: column;
}

.cookiesTitle {
    font-weight: bold;
}

.linkContainer{
    margin:1%;
}

.goBackDesktop{
    color:black;
}
