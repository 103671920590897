.pageContainer {
    padding: 86px 0 0 0;
}

.container {
    display: grid;
    grid-template-columns: repeat(1, 1fr 2fr);
    grid-auto-rows: minmax(95px, auto);
    grid-gap: 3em;
    row-gap: 0em;
    align-items: initial;
    background: var(--lt-grey);
}

.sidebar {
    display: grid;
    grid-column-start: 1;
    grid-row: 1/3;
    height: 100%;
    background: var(--white);
}

.infoPanel {
    display: grid;
    grid-row: 1/1;
}

.results {
    display: grid;
    grid-row: 2/2;
}

.sidebarContainer {
    /*padding: 0 100px;*/
    margin: 2em 0 2em 0;
    padding-left:100px;
}
.resultsContainer {
    padding: 0 100px;
    margin: 2em 0 2em 0;
    /*padding-left:100px;*/
}

.btn-group{
    width:100% !important;
}

.infoPanelContainer {
    padding: 0 100px;
    margin: 2em 0 0 0;
}

/* SideBar styles */
.sidebarContainer h3 {
    font-weight: 600;
    color: var(--dark-blue);
    margin: 0 0 30px 0;
}

/* InfoPanel styles */
.infoPanelContainer h2,
h3 {
    font-weight: 600;
    color: var(--dark-grey);
}

.infoPanelContainer h2,
h3,
p {
    margin: 0 0 20px 0;
}

/* ResultsPanel styles */
.filtertabsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0.5em;
    row-gap: 0.5em;
    align-items: initial;
    margin: 30px 0 0 0;
}

.line {
    border: 1px solid var(--grey);
    margin: 30px 0 0 0;
}

.buttonContainer {
    display: grid;
    /*grid-template-columns: 1fr 1fr;*/
    grid-gap: 1em;
    margin: 0 0 30px 0;
}

.resetContainer {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 5em;
    align-items: center;
}

.resetContainer h5 {
    justify-self: left;
}

.resetContainer div {
    justify-self: right;
}

.resourceItemContainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1.5em;
    align-items: initial;
}

.blueText {
    color: var(--blue);
}

.disclaimer {
    font-size: 14px;
    line-height: 22px;
    margin: 30px 0 0 0;
}

/* text styles */

p {
    font-size: 16px;
    line-height: 24px;
}

h1 {
    font-size: 42px;
    line-height: 48px;
}

h2 {
    font-size: 30px;
    line-height: 38px;
}

h3 {
    font-size: 24px;
    line-height: 32px;
}

h4 {
    font-size: 18px;
    line-height: 26px;
}

h5 {
    font-size: 18px;
    line-height: 26px;
}

/* Responsive styles */

@media screen and (max-width: 1081px) {

    .sidebarContainer,
    .infoPanelContainer,
    .resultsContainer {
        padding: 0 40px;
        grid-gap: 1em;
    }
}

@media screen and (max-width: 1241px) {
    .container {
        grid-template-columns: repeat(1, 2fr 3fr);
    }

    .filtertabsContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 991px) {
    .container {
        grid-template-columns: 1fr;
        column-gap: 0;
    }

    .sidebar {
        grid-row: 1/1;
    }

    .infoPanel {
        grid-row: 2/2;
    }

    .results {
        grid-row: 3/3;
    }

    .sidebarContainer {
        padding: 0 50px;
        margin-top: 0.5em;
    }

    .infoPanelContainer {
        padding: 0 50px;
    }

}

@media screen and (max-width: 799px) {
    .pageContainer {
        padding: 104px 0 0 0;
    }
}

@media screen and (max-width: 584px) {
    .pageContainer {
        padding: 136px 0 0 0;
    }
}

@media screen and (max-width: 568px) {
    .resetContainer {
        grid-template-columns: 1fr;
        grid-gap: 1em;
    }

    .resetContainer div {
        justify-self: left;
    }

    .pageContainer {
        padding: 158px 0 0 0;
    }
}

    @media screen and (max-width: 768px) {
        .buttonContainer {
            grid-gap: 1em;
        }
    }

    @media screen and (max-width: 425px) {
        .buttonContainer {
            grid-template-columns: 1fr;
            grid-gap: 1em;
        }

        .filtertabsContainer {
            grid-template-columns: 1fr;
        }
    }

    @media screen and (max-width: 326px) {

        .sidebarContainer,
        .infoPanelContainer,
        .resultsContainer {
            padding: 0 5%;
        }
    }