
.inputContainer {
    display: grid;
    grid-template-columns: 1fr;
    /*width: 60%;*/
}
.disclaimerText {
    font-size: 14px;
    margin: 0 0 10px 0;
}
.checkBoxContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.privacyText {
    font-weight: 500;
    overflow-wrap: anywhere;
    font-size: 11px;
}
.linkA {
    color: black;
    text-decoration: underline;
}


