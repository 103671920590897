.footerContainer {
    position: relative;
    width: 100%;
    height: auto;
    background-color: var(--dark-blue);
}

.sectionFooterContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 1.5em;
    align-items: stretch;
    padding: 60px 10% 60px 10%;
}


.footerContent1, .footerContent2, .footerContent3 {
    border-right: solid 1px rgba(255, 255, 255, 0.4);
    padding-right: 1.5em;
}

.sectionFooterContainer h3 {
    font-size: 18px;
    line-height: 26px;
}

.sectionFooterContainer p {
    color: var(--white);
    margin: 0 0 10px 0;
}

.hoverStyle {
    color: white;
    transition: all 0.2s ease-out;
    
}
.footerContent3 a {
    text-decoration: none;
}
.hoverStyle:hover {
    color: var(--teal);
}

.logo {
    display: flex;
    flex-wrap: wrap;
    /*width: 250px;*/
    height: 43px;
    margin: 0 0 20px 0;
}

.gmcaLogo {
    display: flex;
    flex-wrap: wrap;
    width: 130px;
    height: auto;
}

.socialContainer {
    display: flex;
    align-items: center;
}

.socialContainer P {
    margin: 0 0 0 10px;
}

.socialIcon {
    height: 16px;
    width: 16px;
}

.footerBottomContainer {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    grid-gap: 5em;
    align-items: start;*/
    margin: 0;
    padding: 40px 10% 40px 10%;
    background-color: var(--darker-blue);
}

.footerLinkContainer {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 20px 0;
}

.footerLinkContainer p {
    margin: 0 20px 0 0;
}

.footerLinkContainer a {
    text-decoration: none;
}


    @media screen and (max-width: 1251px) {
        .sectionFooterContainer {
        grid-template-columns: repeat(2, 1fr) !important;
        grid-gap: 2em !important;
    }

    .footerContent2, .footerContent4 {
        border-right: none !important;
        padding-right: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .sectionFooterContainer {
        grid-template-columns: 1fr !important;
    }

    .footerContent1, .footerContent3 {
        border-right: none !important;
        padding-right: 0 !important;
    }

    .footerContent1, .footerContent2, .footerContent3, .footerContent4 {
        border-bottom: solid 1px rgba(255, 255, 255, 0.4) !important;
        padding-bottom: 1.5em !important;
    }

    .footerBottomContainer {
        grid-template-columns: 4fr 2fr !important;
        grid-gap: 0.5em !important;
    }

    .footerLinkContainer div {
        flex-basis: 100%;
        margin: 0 0 5px 0;
    }
}

@media screen and (max-width: 425px) {
    .logo, .gmcaLogo {
        width: 100% !important;
    }
}
