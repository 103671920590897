.resourceItemContainer {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 5em;
    grid-row-gap: 0;
    align-items: center;
    border-radius: 15px;
    background-color: var(--white);
    text-align: left;
    width: auto;
    padding: 50px;
    box-shadow: 1px 5px 15px rgba(0, 0, 0, .08);
    transition: all 0.3s ease-in-out;
}

.resourceItemContent {
    display: grid;
    grid-row: 1/1;
}

.resourceItemContent h3 {
    justify-self: left;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: var(--dark-blue);
    margin: 0 0 5px 0;
}

.resourceItemContent h4 {
    justify-self: left;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    color: var(--dark-grey);
    margin: 0 0 5px 0;
}

.resourceItemContent p {
    justify-self: left;
    font-weight: 400;
}

.readMore {
    display: flex;
    justify-content: left;
    align-items: baseline;
    cursor: pointer;
}

.readMore p {
    font-weight: 600;
    color: var(--dark-blue);
}

.arrow {
    width: 6px;
    height: auto;
    margin: 0 0 0 20px;
    transform: rotate(0deg);
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.arrow.rotate {
    transform: rotate(90deg);
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.readMoreContainer {
    display: grid;
    grid-column: 1/2;
}

.readMoreContent {
    display: grid;
    grid-column: 1/3;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s cubic-bezier(0, 1, 0, 1);
}

.readMoreContent hr {
    margin: 0 0 20px 0;
}

.readMoreContent.show {
    height: auto;
    max-height: 9999px;
    transition: all 0.2s cubic-bezier(1, 0, 1, 0);
}

.resourceItemCta {
    display: grid;
    grid-row: 1/3;
}

.Btn {
    justify-self: right;
    width: 15px;
    height: auto;
}

.linkA {
    color: white;
    text-decoration: none;
}
/* Responsive styles */
@media screen and (max-width: 1271px) {
    .resourceItemContainer {
        grid-template-columns: 1fr;
    }

    .resourceItemContent {
        grid-column: 1/3;
    }

    .resourceItemCta {
        grid-row: inherit;
        grid-column: 1/3;
    }

}

@media screen and (max-width: 425px) {
    .resourceItemContainer h3 {
        font-size: 10vw;
        line-height: 120%;
    }

    .resourceItemContainer {
        padding: 30px;
    }
}