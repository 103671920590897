
.loaderContainer {
    display: grid;
    justify-content: left;
    margin: 5% auto;
    padding: 0 5%;
}

.loader {
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite;
}

.loader::before, .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 10px solid rgba(255, 222, 0, 1);
    animation: prixClipFix 2s linear infinite;
}

.loader::after {
    transform: rotate3d(90, 90, 0, 180deg );
    border-color: rgba(64, 224, 208, 1)
}

@keyframes rotate {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)
    }

    50% {
        clip-path: polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)
    }

    75%, 100% {
        clip-path: polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)
    }
}