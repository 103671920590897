@font-face {
  font-family: 'gcicons';
  src: url('../fonts/gcicons.eot?65097270');
  src: url('../fonts/gcicons.eot?65097270#iefix') format('embedded-opentype'),
       url('../fonts/gcicons.woff2?65097270') format('woff2'),
       url('../fonts/gcicons.woff?65097270') format('woff'),
       url('../fonts/gcicons.ttf?65097270') format('truetype'),
       url('../fonts/gcicons.svg?65097270#gcicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'gcicons';
    src: url('../font/gcicons.svg?50910380#gcicons') format('svg');
  }
}
*/
[class^="gcicon-"]:before, [class*=" gcicon-"]:before {
  font-family: "gcicons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.gcicon-gc:before { content: '\e800'; } /* '' */
.gcicon-ok:before { content: '\e801'; } /* '' */
.gcicon-left:before { content: '\e802'; } /* '' */
.gcicon-right:before { content: '\e803'; } /* '' */
.gcicon-search:before { content: '\e804'; } /* '' */
.gcicon-phone-1:before { content: '\e805'; } /* '' */
.gcicon-cancel:before { content: '\e806'; } /* '' */
.gcicon-login:before { content: '\e807'; } /* '' */
.gcicon-handshake:before { content: '\e808'; } /* '' */
.gcicon-cart:before { content: '\e809'; } /* '' */
.gcicon-ok-1:before { content: '\e80a'; } /* '' */
.gcicon-down:before { content: '\e80b'; } /* '' */
.gcicon-up:before { content: '\e80c'; } /* '' */
.gcicon-attention:before { content: '\e80d'; } /* '' */
.gcicon-cart-full:before { content: '\e80e'; } /* '' */
.gcicon-award:before { content: '\e80f'; } /* '' */
.gcicon-leaf:before { content: '\e810'; } /* '' */
.gcicon-consulting:before { content: '\e811'; } /* '' */
.gcicon-customer:before { content: '\e812'; } /* '' */
.gcicon-doughnut:before { content: '\e813'; } /* '' */
.gcicon-delivery:before { content: '\e815'; } /* '' */
.gcicon-mail:before { content: '\e81e'; } /* '' */
.gcicon-grow:before { content: '\e81f'; } /* '' */
.gcicon-investment:before { content: '\e820'; } /* '' */
.gcicon-invest-monitor:before { content: '\e821'; } /* '' */
.gcicon-license:before { content: '\e822'; } /* '' */
.gcicon-notepad:before { content: '\e823'; } /* '' */
.gcicon-offer:before { content: '\e824'; } /* '' */
.gcicon-paperclip:before { content: '\e825'; } /* '' */
.gcicon-pie-chart:before { content: '\e826'; } /* '' */
.gcicon-pin:before { content: '\e827'; } /* '' */
.gcicon-cancel-sml:before { content: '\e82b'; } /* '' */
.gcicon-search-sml:before { content: '\e82c'; } /* '' */
.gcicon-quote:before { content: '\e82d'; } /* '' */
.gcicon-quotation:before { content: '\e82f'; } /* '' */
.gcicon-revenue:before { content: '\e830'; } /* '' */
.gcicon-stamp:before { content: '\e831'; } /* '' */
.gcicon-stats-down:before { content: '\e832'; } /* '' */
.gcicon-pinterest:before { content: '\e838'; } /* '' */
.gcicon-stumbleupon:before { content: '\e839'; } /* '' */
.gcicon-comments-alt:before { content: '\e83a'; } /* '' */
.gcicon-comments:before { content: '\e83b'; } /* '' */
.gcicon-caravan:before { content: '\e83c'; } /* '' */
.gcicon-car-alt:before { content: '\e83d'; } /* '' */
.gcicon-car:before { content: '\e83e'; } /* '' */
.gcicon-bus:before { content: '\e83f'; } /* '' */
.gcicon-bullhorn:before { content: '\e842'; } /* '' */
.gcicon-pension-small:before { content: '\e843'; } /* '' */
.gcicon-bubble:before { content: '\e844'; } /* '' */
.gcicon-minus:before { content: '\e845'; } /* '' */
.gcicon-plus:before { content: '\e846'; } /* '' */
.gcicon-bridge:before { content: '\e848'; } /* '' */
.gcicon-bi-cycle:before { content: '\e849'; } /* '' */
.gcicon-anchor:before { content: '\e84a'; } /* '' */
.gcicon-train-alt:before { content: '\e84b'; } /* '' */
.gcicon-train:before { content: '\e84c'; } /* '' */
.gcicon-taxi:before { content: '\e84d'; } /* '' */
.gcicon-support:before { content: '\e84e'; } /* '' */
.gcicon-scooter:before { content: '\e850'; } /* '' */
.gcicon-road:before { content: '\e851'; } /* '' */
.gcicon-briefcase-1:before { content: '\e852'; } /* '' */
.gcicon-apartment:before { content: '\e853'; } /* '' */
.gcicon-agenda:before { content: '\e854'; } /* '' */
.gcicon-target-revenue:before { content: '\e855'; } /* '' */
.gcicon-target:before { content: '\e856'; } /* '' */
.gcicon-tag:before { content: '\e857'; } /* '' */
.gcicon-stats-up:before { content: '\e858'; } /* '' */
.gcicon-comments-reply:before { content: '\e859'; } /* '' */
.gcicon-write:before { content: '\e85a'; } /* '' */
.gcicon-book:before { content: '\e85b'; } /* '' */
.gcicon-envelope:before { content: '\e85c'; } /* '' */
.gcicon-helicopter:before { content: '\e85d'; } /* '' */
.gcicon-certificate:before { content: '\e85e'; } /* '' */
.gcicon-clipboard-1:before { content: '\e85f'; } /* '' */
.gcicon-inbox:before { content: '\e860'; } /* '' */
.gcicon-phone:before { content: '\e861'; } /* '' */
.gcicon-compass:before { content: '\e862'; } /* '' */
.gcicon-library:before { content: '\e863'; } /* '' */
.gcicon-checkmark:before { content: '\e864'; } /* '' */
.gcicon-checkmark-circle:before { content: '\e865'; } /* '' */
.gcicon-circle-minus:before { content: '\e866'; } /* '' */
.gcicon-circle-plus:before { content: '\e867'; } /* '' */
.gcicon-close:before { content: '\e868'; } /* '' */
.gcicon-cross-circle:before { content: '\e869'; } /* '' */
.gcicon-phone-set:before { content: '\e86a'; } /* '' */
.gcicon-world:before { content: '\e86c'; } /* '' */
.gcicon-eye:before { content: '\e86d'; } /* '' */
.gcicon-flag:before { content: '\e86e'; } /* '' */
.gcicon-service:before { content: '\e86f'; } /* '' */
.gcicon-popup:before { content: '\e870'; } /* '' */
.gcicon-postcard:before { content: '\e871'; } /* '' */
.gcicon-coffee-cup:before { content: '\e872'; } /* '' */
.gcicon-flag-alt:before { content: '\e873'; } /* '' */
.gcicon-invention:before { content: '\e874'; } /* '' */
.gcicon-key-1:before { content: '\e875'; } /* '' */
.gcicon-lifering:before { content: '\e876'; } /* '' */
.gcicon-list:before { content: '\e877'; } /* '' */
.gcicon-lock:before { content: '\e878'; } /* '' */
.gcicon-lock-alt:before { content: '\e879'; } /* '' */
.gcicon-information:before { content: '\e87a'; } /* '' */
.gcicon-infinite:before { content: '\e87b'; } /* '' */
.gcicon-help:before { content: '\e87c'; } /* '' */
.gcicon-plus-1:before { content: '\e87e'; } /* '' */
.gcicon-pointer:before { content: '\e87f'; } /* '' */
.gcicon-power-switch:before { content: '\e880'; } /* '' */
.gcicon-protection:before { content: '\e881'; } /* '' */
.gcicon-question-circle:before { content: '\e882'; } /* '' */
.gcicon-radio-button:before { content: '\e883'; } /* '' */
.gcicon-heart-filled-1:before { content: '\e884'; } /* '' */
.gcicon-network:before { content: '\e885'; } /* '' */
.gcicon-seo:before { content: '\e886'; } /* '' */
.gcicon-target-customer:before { content: '\e887'; } /* '' */
.gcicon-bar-chart:before { content: '\e888'; } /* '' */
.gcicon-graph:before { content: '\e889'; } /* '' */
.gcicon-video:before { content: '\e88a'; } /* '' */
.gcicon-leaf-1:before { content: '\e88b'; } /* '' */
.gcicon-mashroom:before { content: '\e88c'; } /* '' */
.gcicon-sprout:before { content: '\e88d'; } /* '' */
.gcicon-tree:before { content: '\e88e'; } /* '' */
.gcicon-trees:before { content: '\e88f'; } /* '' */
.gcicon-flower:before { content: '\e890'; } /* '' */
.gcicon-flags:before { content: '\e891'; } /* '' */
.gcicon-spray:before { content: '\e892'; } /* '' */
.gcicon-baloon:before { content: '\e893'; } /* '' */
.gcicon-cake:before { content: '\e894'; } /* '' */
.gcicon-fireworks:before { content: '\e895'; } /* '' */
.gcicon-thought:before { content: '\e896'; } /* '' */
.gcicon-home:before { content: '\e897'; } /* '' */
.gcicon-crown:before { content: '\e898'; } /* '' */
.gcicon-travel:before { content: '\e899'; } /* '' */
.gcicon-pyramids:before { content: '\e89a'; } /* '' */
.gcicon-night:before { content: '\e89b'; } /* '' */
.gcicon-rain:before { content: '\e89c'; } /* '' */
.gcicon-sun:before { content: '\e89d'; } /* '' */
.gcicon-cloud:before { content: '\e89e'; } /* '' */
.gcicon-laptop:before { content: '\e89f'; } /* '' */
.gcicon-user:before { content: '\e8a0'; } /* '' */
.gcicon-website:before { content: '\e8a1'; } /* '' */
.gcicon-alarm:before { content: '\e8a2'; } /* '' */
.gcicon-archive:before { content: '\e8a3'; } /* '' */
.gcicon-ban:before { content: '\e8a4'; } /* '' */
.gcicon-bulb:before { content: '\e8a5'; } /* '' */
.gcicon-calendar:before { content: '\e8a6'; } /* '' */
.gcicon-bookmark:before { content: '\e8a7'; } /* '' */
.gcicon-camera:before { content: '\e8a8'; } /* '' */
.gcicon-cog-1:before { content: '\e8a9'; } /* '' */
.gcicon-cogs:before { content: '\e8aa'; } /* '' */
.gcicon-control-panel:before { content: '\e8ab'; } /* '' */
.gcicon-database:before { content: '\e8ac'; } /* '' */
.gcicon-display:before { content: '\e8ad'; } /* '' */
.gcicon-files:before { content: '\e8ae'; } /* '' */
.gcicon-folder:before { content: '\e8af'; } /* '' */
.gcicon-headphone:before { content: '\e8b0'; } /* '' */
.gcicon-image:before { content: '\e8b1'; } /* '' */
.gcicon-keyboard:before { content: '\e8b2'; } /* '' */
.gcicon-laptop-phone:before { content: '\e8b3'; } /* '' */
.gcicon-map:before { content: '\e8b4'; } /* '' */
.gcicon-map-marker:before { content: '\e8b5'; } /* '' */
.gcicon-microphone:before { content: '\e8b6'; } /* '' */
.gcicon-mobile-1:before { content: '\e8b7'; } /* '' */
.gcicon-mouse:before { content: '\e8b8'; } /* '' */
.gcicon-package:before { content: '\e8b9'; } /* '' */
.gcicon-plug:before { content: '\e8ba'; } /* '' */
.gcicon-printer:before { content: '\e8bb'; } /* '' */
.gcicon-rocket:before { content: '\e8bc'; } /* '' */
.gcicon-share:before { content: '\e8bd'; } /* '' */
.gcicon-share-alt:before { content: '\e8be'; } /* '' */
.gcicon-share-alt-1:before { content: '\e8bf'; } /* '' */
.gcicon-shopping-basket:before { content: '\e8c0'; } /* '' */
.gcicon-signal:before { content: '\e8c1'; } /* '' */
.gcicon-star:before { content: '\e8c2'; } /* '' */
.gcicon-star-empty:before { content: '\e8c3'; } /* '' */
.gcicon-star-filled:before { content: '\e8c4'; } /* '' */
.gcicon-trash-can:before { content: '\e8c5'; } /* '' */
.gcicon-heart:before { content: '\e8c6'; } /* '' */
.gcicon-hand:before { content: '\e8c7'; } /* '' */
.gcicon-restaurant:before { content: '\e8c8'; } /* '' */
.gcicon-capsule:before { content: '\e8c9'; } /* '' */
.gcicon-dumbbell:before { content: '\e8ca'; } /* '' */
.gcicon-pulse:before { content: '\e8cb'; } /* '' */
.gcicon-wheelchair:before { content: '\e8cc'; } /* '' */
.gcicon-shield:before { content: '\e8cd'; } /* '' */
.gcicon-teabag:before { content: '\e8ce'; } /* '' */
.gcicon-thumbs-down:before { content: '\e8cf'; } /* '' */
.gcicon-thumbs-up:before { content: '\e8d0'; } /* '' */
.gcicon-ticket:before { content: '\e8d1'; } /* '' */
.gcicon-ticket-alt:before { content: '\e8d2'; } /* '' */
.gcicon-unlock:before { content: '\e8d3'; } /* '' */
.gcicon-warning:before { content: '\e8d4'; } /* '' */
.gcicon-bolt:before { content: '\e8d5'; } /* '' */
.gcicon-bolt-alt:before { content: '\e8d6'; } /* '' */
.gcicon-check-box:before { content: '\e8d7'; } /* '' */
.gcicon-world-alt:before { content: '\e8d8'; } /* '' */
.gcicon-brush-1:before { content: '\e8d9'; } /* '' */
.gcicon-money-location:before { content: '\e8da'; } /* '' */
.gcicon-credit-cards:before { content: '\e8db'; } /* '' */
.gcicon-coin:before { content: '\e8dc'; } /* '' */
.gcicon-calculator:before { content: '\e8dd'; } /* '' */
.gcicon-wallet:before { content: '\e8de'; } /* '' */
.gcicon-pound:before { content: '\e8df'; } /* '' */
.gcicon-money-protection:before { content: '\e8e0'; } /* '' */
.gcicon-wheelbarrow:before { content: '\e8e1'; } /* '' */
.gcicon-paint-bucket:before { content: '\e8e2'; } /* '' */
.gcicon-hammer:before { content: '\e8e3'; } /* '' */
.gcicon-bricks:before { content: '\e8e4'; } /* '' */
.gcicon-ruler:before { content: '\e8e5'; } /* '' */
.gcicon-shovel:before { content: '\e8e6'; } /* '' */
.gcicon-tshirt:before { content: '\e8e7'; } /* '' */
.gcicon-down-open:before { content: '\f004'; } /* '' */
.gcicon-up-open:before { content: '\f005'; } /* '' */
.gcicon-right-open:before { content: '\f006'; } /* '' */
.gcicon-left-open:before { content: '\f007'; } /* '' */
.gcicon-facebook-1:before { content: '\f09a'; } /* '' */
.gcicon-menu:before { content: '\f0c9'; } /* '' */
.gcicon-gplus:before { content: '\f0d5'; } /* '' */
.gcicon-linkedin:before { content: '\f0e1'; } /* '' */
.gcicon-angle-double-left:before { content: '\f100'; } /* '' */
.gcicon-angle-double-right:before { content: '\f101'; } /* '' */
.gcicon-circle-empty:before { content: '\f10c'; } /* '' */
.gcicon-youtube-play:before { content: '\f16a'; } /* '' */
.gcicon-instagram:before { content: '\f16d'; } /* '' */
.gcicon-cc-visa:before { content: '\f1f0'; } /* '' */
.gcicon-podcast:before { content: '\f2ce'; } /* '' */
.gcicon-vimeo:before { content: '\f306'; } /* '' */
.gcicon-twitter:before { content: '\f309'; } /* '' */
.gcicon-facebook:before { content: '\f30c'; } /* '' */
.gcicon-linkedin-1:before { content: '\f318'; } /* '' */
.gcicon-instagram-1:before { content: '\f32d'; } /* '' */
.gcicon-paypal:before { content: '\f342'; } /* '' */
