
.modal-backdrop {
    background-color: rgb(0, 40, 86, 0.9);
}
.modal-backdrop.show{
    opacity:0.75;
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5em;
    margin-bottom: 10px;
}

.form-check label {
    margin: 2px 0 5px 5px;
}
.form-check-input {
    background-color: var(--grey)
}
.form-check-input:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid var(--blue);
}
.form-check-input:checked {
    background-color: var(--dark-blue);
}
.form-check-input[type=checkbox] {
    border-radius:unset;
}
.form-control {
    border: 2px solid var(--dark-blue);
    border-radius: 5px;
    padding: 10px;
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid var(--blue);
}
.invalid-feedback{
    margin-top:-15px;
}


input,
textarea {
    border: 2px solid var(--dark-blue);
    border-radius: 5px;
    padding: 10px;
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

input:focus,
textarea:focus {
    outline: none;
    border: 2px solid var(--blue);
}


@media screen and (max-width: 768px) {
    .checkBoxContainer {
        grid-template-columns: 1fr;
    }
}