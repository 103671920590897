
.question {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;
}

    .question p {
        font-weight: 600;
        color: var(--dark-blue);
        margin: 0 0 10px 0;
    }

.option {
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s cubic-bezier(0, 1, 0, 1);
}

    .option.show {
        height: auto;
        max-height: 9999px;
        transition: all 0.2s cubic-bezier(1, 0, 1, 0);
    }

    .option label {
        display: flex;
        margin: 0 0 10px 0;
    }

.hide {
    display: none;
    visibility: hidden;
}

.show {
    display: block;
    visibility: visible;
}
/* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    /* Hide the browser's default radio button */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--grey);
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: var(--mid-grey);
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
    background-color: var(--blue);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--white);
}
