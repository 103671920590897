.accessContainer {
    padding: 5%;
    display: flex;
    flex-direction: column;
}

.accessTitle {
    font-weight: bold;
}

.accessContainer p {
    margin-bottom: 1%;
}

.accessContainer .link {
    color: black
}

    .accessContainer .link:hover {
        color: var(--main-font-colour)
    }
.linkContainer {
    margin: 1%;
}

.goBackDesktop {
    color: black;
}