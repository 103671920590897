.btnActive {
    /*display: grid;*/
    align-items: center;
    border: 2px solid var(--dark-blue);
    border-radius: 50px;
    background-color: var(--dark-blue);
    color: var(--white);
    text-align: center;
    cursor: pointer;
    padding: 10px 40px 10px 40px;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

.btnActive:hover {
    border: 2px solid var(--blue);
    background-color: var(--blue);
}
.btnActive span {
    color:white;
    padding: 0 0 0 10px;
 }

.btnSecondary {
    display: grid;
    align-items: center;
    border: 2px solid var(--dark-blue);
    border-radius: 50px;
    background-color: var(--lt-grey);
    color: var(--dark-blue);
    text-align: center;
    cursor: pointer;
    padding: 10px 40px 10px 40px;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
}

    .btnSecondary:hover {
        border: 2px solid var(--dark-blue);
        background-color: var(--lt-blue);
        color: var(--blue);
    }
    .btnSecondary:active {
        background-color: var(--dark-blue) !important;
        background-color: var(--blue) !important;
    }

.btnDisabled {
    display: grid;
    align-items: center;
    border: 2px solid var(--grey) !important;
    border-radius: 50px;
    background-color: var(--grey) !important;
    color: var(--inactive-grey) !important;
    text-align: center;
    padding: 10px 40px 10px 40px;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    cursor:default !important;
}
.btnAdminSelect {
    display: grid;
    align-items: center;
    border: 2px solid var(--dark-blue);
    border-radius: 50px;
    background-color: var(--dark-blue);
    color: var(--white);
    text-align: center;
    cursor: pointer;
    padding: 10px 20px 10px 20px;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    margin-top:5px;
}
.btnReset {
    display: grid;
    align-items: center;
    border: 2px solid #6c757d;
    border-radius: 50px;
    background-color: #6c757d;
    color: var(--white);
    text-align: center;
    cursor: pointer;
    padding: 10px 20px 10px 20px;
    font-size: 18px;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    margin-top: 5px;
}
    .btnReset:hover {
        background-color: #5c636a;
    }
