.termsContainer {
    padding: 5%;
    display: flex;
    flex-direction: column;
}

.termsTitle {
    font-weight: bold;
}

.termsContainer p {
    margin-bottom: 1%;
}

.termsContainer .link {
    color: var(--marketing-blue)
}

    .termsContainer .link:hover {
        color: var(--main-font-colour)
    }

.boxHeader {
    background-color: var(--dark-green);
    color: white;
    text-align: center;
    padding-top: 10px;
}
.boxPanels{
    display:flex;
    flex-direction:row;
    gap:10px;
    margin:10px 0 0 0;
}
.boxNumerals {
    flex: 1 1 30rem;
    background-color: var(--dark-green);
    color:white;
    text-align:center;
    padding:1%;
}
.boxText {
    flex: 1 1 70rem;
    background-color: var(--green-colour);
    padding: 1%;
}
.termsContainer ul {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 15px;
}

.termsContainer ul li {
    line-height: 1.5rem;
    position: relative;
    padding-left: 20px;
    margin-bottom: 5px;
    padding-top: 1px;
}
/* change this old GE icon if needed */
.termsContainer ul li:before {
    position: absolute;
    top: .5px;
    left: 0;
    font-family: 'gcicons';
    content: "\e813";
    font-size: 10px;
    color: var(--green-colour);
}
.linkContainer {
    margin: 1%;
}

.goBackDesktop {
    color: black;
}
