
.accordion {
    --bs-accordion-btn-padding-x: 0rem;
    --bs-accordion-border-color: #fff;
    --bs-accordion-border-radius: 0;
}

.accordion-button {
    background-color: #fff !important;
    font-weight: 500 !important;
    padding: 0 0.5rem 1.25rem 0;
}

    .accordion-button:focus {
        box-shadow: none;
    }

    .accordion-button:not(.collapsed) {
        color: var(--dark-blue);
        font-weight: 900 !important;
    }

.accordion-body {
    padding: 0 0 0.75rem 0.3rem;
}

.accordion-item {
    border-bottom: 1px solid var(--grey);
    margin-bottom: 0.5rem;
}
