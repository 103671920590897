.pageContainer {
    padding: 86px 4% 0 4%;
    display: flex;
    flex-direction: column;
}
.Results{
    
}
.Reults h2, .Results h3{
    margin:0;
}
.ResourceBox{
    border:1px solid black;
    padding:1%;
}

.ResourceBox button{
    float:right;
    height:65px;
}
.form-control-plaintext {
    border: solid transparent !important;
}
.SearchContainer {
    position: sticky;
    top: 80px;
    background-color: white;
    z-index: 1000;
    padding:1%;
    border:1px solid black;
}
.InputContainer{
    padding: 2.5% 0 2.5% 0;
}

.buttonContainer {
    display: flex;
    align-content: space-between;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

