.tabContainer {
    display: grid;
    grid-template-columns: 5fr 1fr;
    align-items: center;
    border: 3px solid var(--blue);
    border-radius: 20px;
    background-color: var(--blue);
    color: #ffffff;
    text-align: left;
    width: auto;
    padding: 2px 10px;
    transition: all 0.3s ease-in-out;
}

.tabContainer div {
    display: grid;
}

.tabContainer p {
    justify-self: left;
    font-size: 11px;
    line-height: 15px;
    color: #ffffff;
    margin: 0 10px 0 0;
    font-weight:bold;
    min-height:25px;
}

.removeIcon {
    justify-self: right;
    width: 15px;
    height: auto;
    cursor: pointer;
}